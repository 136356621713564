import React, { FC } from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-gaviscon/src/components/Layout"

import TopBanner from "gatsby-theme-gaviscon/src/components/TopBanner"
import ProductCardsList from "gatsby-theme-gaviscon/src/components/ProductCardsList"
import WhereToBuy from "gatsby-theme-gaviscon/src/components/WhereToBuy"
import Testimonials from "gatsby-theme-gaviscon/src/components/Testimonials"
import FaqSection from "gatsby-theme-gaviscon/src/components/FaqSection"
import BodyRenderer from "gatsby-theme-gaviscon/src/components/common/BodyRenderer"
import CategoriesNav from "gatsby-theme-gaviscon/src/components/common/CategoriesNav"
import BreadCrumbs from "gatsby-theme-gaviscon/src/components/common/BreadCrumbs"
import SevenSymptomsBanner from "gatsby-theme-gaviscon/src/components/symptomsCampaign/SevenSymptomsBanner"
import TextBlock from "gatsby-theme-gaviscon/src/components/common/TextBlock"

import { TProductPageComponentProps } from "./models"
import "./ProductListingPage.scss"

const elements = {
  "Where to buy": ({ properties }, keyId) => (
    <WhereToBuy key={keyId} {...properties} />
  ),
  "Testimonials Carousel": ({ properties }, keyId) => (
    <Testimonials key={keyId} {...properties} />
  ),
  "FAQ block": ({ properties }, keyId) => (
    <FaqSection key={keyId} {...properties} />
  ),
}

const ProductPage: FC<TProductPageComponentProps> = ({
  data: {
    page: {
      pageName,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      openGraphImageUrl,
      limit,
      limitMobile,
      more,
      filterTitle,
      filterSubtitle,
      subCategories,
      masterTag,
      ariaMore,
      linkCompare,
      showCompareLink,
      topBanner: [{ properties: topBannerData }],
      body,
      linkQuiz,
      hideVariant,
      isFamily,
      showBreadcrumbs,
      sevenSymptomsBanner,
      faqBlock,
      mainHeaderTxt,
      mainHeaderParagraph,
      textBlock,
    },
    relatedProducts,
    siteSettings,
    commonSettings,
    header,
    footer,
  },
  pageContext: { productsLinks, breadCrumbs },
}) => {
  const formattedRelatedProducts = {
    nodes: relatedProducts.nodes.map(item => ({ ...item, hideVariant })),
  }

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      <TopBanner
        {...topBannerData}
        imageStrategy="initial"
        imageStyles={{ position: "static", width: "auto" }}
        classes="product-top-banner"
      />
      {subCategories ? (
        <CategoriesNav
          selectedTag={masterTag}
          categories={subCategories}
          filterTitle={filterTitle}
          filterSubtitle={filterSubtitle}
          mainHeaderTxt={mainHeaderTxt}
          mainHeaderParagraph={mainHeaderParagraph}
        />
      ) : null}
      <ProductCardsList
        {...{
          relatedProducts: formattedRelatedProducts,
          limit,
          limitMobile,
          more,
          ariaMore,
          linkCompare,
          showCompareLink,
          linkQuiz,
          productsLinks,
          isFamily,
          listingName: pageName,
        }}
      />
      <BodyRenderer bodyData={body} bodyStructure={elements} />
      {sevenSymptomsBanner?.length ? (
        <SevenSymptomsBanner data={sevenSymptomsBanner} />
      ) : null}
      {textBlock?.length ? (
        <div className="container-fluid product-listing__text-block">
          <TextBlock text={textBlock[0].properties.content} />
        </div>
      ) : null}
      {faqBlock?.length ? <FaqSection {...faqBlock[0].properties} /> : null}
    </Layout>
  )
}

export const query = graphql`
  query ProductListingQueryModified(
    $productsLinks: [String]
    $link: String
    $lang: String
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: productListing(link: { eq: $link }, lang: { eq: $lang }) {
      pageName
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        key
        value
      }
      openGraphImageUrl
      id
      limit
      limitMobile
      showCompareLink
      linkCompare {
        url
        name
      }
      more
      ariaMore
      productsLinks
      hideVariant
      filterTitle
      filterSubtitle
      mainHeaderTxt
      mainHeaderParagraph
      subCategories {
        name
        link {
          name
          url
        }
      }
      masterTag {
        name
        link {
          name
          url
        }
      }
      isFamily
      showBreadcrumbs
      topBanner {
        properties {
          description
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 420, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          imageAlt
          mobileImage {
            ...FragmentGatsbyProps
            gatsbyImage {
              ...FragmentSmallImage
            }
            mobileImage {
              childImageSharp {
                fluid(maxWidth: 320, maxHeight: 220, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          mobileImageAlt
          title {
            value
          }
        }
      }
      linkQuiz {
        properties {
          ariaLabel
          image {
            ...FragmentGatsbyImage
          }
          imageAlt
          title
          link {
            name
            url
          }
        }
      }
      sevenSymptomsBanner {
        ...FragmentSevenSymptomsBanner
      }
      faqBlock {
        properties {
          title
          color
          selectedIndex
          isExpanded
          items {
            properties {
              answer
              question
            }
          }
        }
      }
      textBlock {
        properties {
          content
          maxWidth
        }
      }
      body {
        ... on TTestimonialsCarouselStructure {
          structure
          properties {
            color
            title
            slides {
              properties {
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
                description
                ariaLink
                link {
                  name
                  url
                }
              }
            }
          }
        }
        ... on TWheretobuyStructure {
          structure
          properties {
            color
            title
            listMode
            limit
            limitMobile
            cards {
              properties {
                imageAlt
                type
                title
                link {
                  url
                  target
                  queryString
                }
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
        }
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $productsLinks } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyCardImage
        }
        linkFamily {
          url
        }
        imageFamily {
          ...FragmentGatsbyCardImage
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          name
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        currentFormat {
          properties {
            label
          }
        }
        sku
        title
        category
        sizes {
          properties {
            label
          }
        }
        flavours {
          properties {
            label
          }
        }
      }
    }
  }
`

export default ProductPage
