import React, { FC } from 'react';

import DropdownCategories from 'gatsby-theme-gaviscon/src/components/DropdownCategories';
import CategoriesNavItem from 'gatsby-theme-gaviscon/src/components/common/CategoriesNav/CategoriesNavItem';
import { ICategoriesNav } from './models';
import './CategoriesNav.scss';

const CategoriesNav: FC<ICategoriesNav> = ({
  selectedTag,
  categories,
  filterTitle,
  filterSubtitle,
  mainHeaderTxt,
  mainHeaderParagraph,
}) => {
  return categories.length ? (
    <div className="gs-categories-nav container-fluid">
      {mainHeaderTxt ? (
        <div className="gs-categories-nav__main-header-txt">
          <h1>{mainHeaderTxt}</h1>
          <p>{mainHeaderParagraph}</p>
        </div>
      ) : null}
      {filterTitle ? (
        <h2 className="gs-categories-nav__title">{filterTitle}</h2>
      ) : null}
      {filterSubtitle ? (
        <div className="gs-categories-nav__subtitle">{filterSubtitle}</div>
      ) : null}
      <div className="mobile-hide">
        <nav className="gs-categories-nav__list">
          <ul>
            {categories?.map(
              tag =>
                tag &&
                selectedTag && (
                  <CategoriesNavItem
                    key={tag.id}
                    tag={tag}
                    selected={tag.name === selectedTag[0].name}
                  />
                )
            )}
          </ul>
        </nav>
      </div>

      <div className="desktop-hide">
        <DropdownCategories tags={categories} />
      </div>
    </div>
  ) : null;
};

export default CategoriesNav;
